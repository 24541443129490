.slider-container {
  position: relative;
  width: 100%;
  height: 56px;
  border-radius: 28px;
  background-color: #f5f5f5;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider {
  height: 56px;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: flex-end;
}

.slider-enabled {
  border-radius: 28px;
  background: linear-gradient(to left, #292e2b, #8a9388);
}

.icon-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  left: -2px;
  border-radius: 24px;
  color: #f5f5f5;
  background: linear-gradient(to right, #292e2b, #8a9388);
  margin-top: 4px;
  height: 48px;
  width: 48px;
}

.icons-enabled {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 56px;
  color: #f5f5f5;
  border-style: solid;
  border-width: 2px;
  border-color: #4f565f;
  border-radius: 28px;
  background-color: #2f333a;
}

.slider-label {
  color: black;
  position: absolute;
}
