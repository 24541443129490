.redeem-animation {
  opacity: 1;
  position: absolute;
  background: #000000cd;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s;
}
