.box-doree {
  background: linear-gradient(170deg, #c1a30b, #feea62, #c1a30b);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.box-doree-text {
  font-size: 21px;
  margin: 0;
}

.box-doree .limited {
  font-size: 12px;
  margin: 0;
}

.box-doree-text span {
  font-weight: 700;
}

.box-doree-btn {
  background-color: #1e211f;
  color: white;
  font-size: 12px;
  border-radius: 50px;
  width: 100px;
  height: 35px;
  align-self: center;
  margin-right: 15px;
  margin-left: auto;
}
