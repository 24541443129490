.page-offers .card {
  margin: 16px;
}

.container-chips {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  margin: 10px 15px;
}
