.killbill-card-page {
  --background: linear-gradient(45deg, #292e2b 19.27%, #8a9388 100%);
}

.killbill-card-page .kb-card-body {
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
}

.killbill-card-page .information,
.killbill-card-page .killbill-name,
.killbill-card-page .shop-name {
  color: #ffffff;
}

.killbill-card-page .information {
  margin: 0;
}

.killbill-card-page .kb-card-button {
  height: 56px;
  width: 56px;
  background-color: white;
  border-radius: 50%;
  color: #00aa77;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.killbill-card-page .shop-name {
  margin: 24px 0;
  font-size: 28px;
  font-weight: 600;
}
.killbill-card-page .kb-card {
  background: linear-gradient(
    43.27deg,
    #020202 2.84%,
    #424241 43.65%,
    #2b2b2b 50.45%,
    #181818 58.22%,
    #0a0a0a 67.94%,
    #020202 79.6%,
    #000000 100%
  );
  border-radius: 12px;
}

.killbill-card-page ion-card {
  margin-top: 24px;
}

.killbill-card-page ion-card-content {
  padding: 20px;
}

.killbill-card-page .kb-gold {
  background: linear-gradient(
    119.95deg,
    #c1a30b 0.92%,
    #c7b74d 59.82%,
    #edd74b 65.71%,
    #dac02f 73.56%,
    #ccb01b 82.4%,
    #c3a60f 90.25%,
    #c1a30b 99.09%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.killbill-card-page .kb-card-title {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
.kb-card-content {
  color: #ffffff;
  font-weight: 300;
}

.kb-card-time {
  font-size: 60px;
  text-align: center;
  padding: 0 0 0 0;
  font-weight: 700;
}

.kb-card-date {
  font-size: 20px;
  text-align: center;
  margin-top: 0;
  font-weight: 600;
}

.kb-card-expire {
  text-align: right;
}

.kb-card-bottom {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 30px;
}

.kb-card-icon {
  height: 50px;
  width: 50px;
}

.killbill-name {
  font-size: 30px;
  font-weight: 300;
  word-spacing: -9px;
  margin: 7px 0 0 10px;
}
.kb-card-span {
  font-weight: 600;
}
