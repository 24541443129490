.list-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0 8px;
}

.list-container.top-offers {
  flex-wrap: nowrap;
  overflow-x: scroll;
}

.list-container.top-offers .list-item-wrapper {
  min-width: 200px;
}

ion-list-header {
  font-size: 24px;
}

ion-text {
  margin: auto;
}
