.welcome-offer-card {
  padding: 16px;
}

.welcome-offer-card .header {
  display: inline-flex;
  padding: 0;
  gap: 16px;
  justify-content: space-between;
  width: 100%;
}

.welcome-offer-card .header .left {
  flex: 1;
}

.welcome-offer-card ion-card-content {
  padding: 0;
  margin-top: 8px;
}

.welcome-offer-card .spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.welcome-offer-card .circle-validated {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: linear-gradient(
    119.95deg,
    #c1a30b 0.92%,
    #ffeb64 59.82%,
    #edd74b 65.71%,
    #dac02f 73.56%,
    #ccb01b 82.4%,
    #c3a60f 90.25%,
    #c1a30b 99.09%
  );
  display: flex;
  align-items: center;
  justify-content: center;
}

.welcome-offer-card .circle-validated ion-icon {
  font-size: 30px;
  color: #ffffff;
}

.welcome-offer-card .card-condition-store {
  text-decoration: underline;
  font-weight: normal;
  color: #a1a6a2;
}
