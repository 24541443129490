.lb-textfield {
  position: relative;
}

.lb-textfield label {
  position: absolute;
  top: -7px;
}

.lb-textfield input {
  color: #000000;
  background-color: white;
  font-size: 16px;
  height: 40px;
  padding: 4px 16px;
  border-color: var(--lb-color-black-2);
  border-radius: 8px;
  border-width: 1px;
  width: 100%;
}

.lb-textfield-error-message {
  font-size: 11px;
  padding-top: 8px;
  padding-left: 5px;
  color: red;
}
