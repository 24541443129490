.page-validation .container {
  padding: 16px;
}

.page-validation .circle-light {
  width: 175px;
  height: 175px;
  border-radius: 50%;
  background-color: rgba(193, 163, 11, 0.2);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}

.page-validation .circle-dark {
  width: 127px;
  height: 127px;
  border-radius: 50%;
  background: #d1b622;
  position: absolute;
  text-align: center;
}

.page-validation .circle-icon {
  z-index: 1;
}

.page-validation .txt-congrats {
  margin-top: 16px;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}

.page-validation .container-logo {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 10px;
  align-items: center;
}

.kb-logo {
  background-repeat: no-repeat;
  background-size: cover;
  width: 40.4px;
  height: 40.57px;
}

.btn-offers {
  color: white;
  width: 100%;
  font-weight: 600;
  background: #d1b622;
  border-radius: 12px;
  padding: 16px 0px;
  text-align: center;
  margin: 16px auto 0;
}

.page-validation .paid-section {
  padding: 16px 0;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  margin: 16px 0;
}
.page-validation .txt-total-confirmation {
  margin: 0 0 8px;
  font-size: 12px;
  font-weight: 400;
  color: #888f9b;
  text-align: center;
}

.page-validation .paid-section .logo-amount {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.page-validation .paid-section .logo-amount .img {
  width: 35px;
  height: 35px;
}

.page-validation .paid-section .logo-amount .text {
  font-size: 22px;
}

.page-validation .paid-section .logo-amount .txt-total-prix {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
}

.page-validation .paid-section .logo-amount .txt-total-paid {
  font-size: 16px;
  font-weight: 400;
  color: #888f9b;
  text-align: center;
}
