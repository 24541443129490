.btn-auth,
.btn-logout {
  width: 100%;
  padding: 12px 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.btn-auth[disabled] {
  opacity: 0.6;
}

.btn-auth {
  background: #d1b622;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
}

.btn-auth[disabled] {
  opacity: 0.6;
}

.account-separator {
  position: relative;
  width: 100%;
  text-align: center;
  border-top: 1px solid #aab1ba;
  margin: 16px 0;
}

.account-separator span {
  color: #aab1ba;
  position: absolute;
  width: 100px;
  background-color: #FFFFFF;
  top: -10px;
  left: calc(50% - 50px);
  padding: 0px 8px;
}

.link-auth {
  background: none;
  font-size: 15px;
  width: 100%;
  color: #000000;
}

.link-auth span {
  font-weight: bold;
}

/** LOGO STYLE */

.logo {
  display: inline-flex;
  align-items: center;
}

.logo .text {
  font-family: 'Sans Encode Condensed';
  font-weight: 200;
  margin: 0;
  margin-left: 8px;
}

.logo .img {
  background-image: url(../../../../public/assets/imgs/icon.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 30px;
  height: 30px;
}

.logo .text span {
  font-family: 'Sans Encode Condensed';
  font-weight: 500;
}

/** CONTAINER STYLE */

.logo-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logo-container .website {
  font-size: 13px;
  color: #808080;
  text-decoration: underline;
}

.logo-container .logo .text {
  color: #131212;
  font-size: 18px;
}

.logo-container .logo .img {
  height: 28px;
  width: 28px;
}
