.map-box {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.google-map-box {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.store-card {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  z-index: 10;
}

.container-chips-map {
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 2px;
  margin: 8px 16px;
  z-index: 3;
}
ion-tab-button {
  --background-focused: #c1a30b;
  --color-selected: #c1a30b;
}

.map-box .popup-buy {
  position: absolute;
  background: transparent;
  backdrop-filter: blur(4px);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  padding: 16px;
}

.map-box .popup-buy > div {
  background: white;
  border-radius: 16px;
  overflow: hidden;
  padding-bottom: 16px;
  width: 100%;
  position: relative;
}
.map-box .popup-buy > div > ion-icon {
  color: white;
  position: absolute;
  right: 0;
  top: 0;
  padding: 16px;
}
