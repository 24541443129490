.auth-register-page {
  color: #000000;
}

.auth-register-page .titre {
  font-size: 28px;
  text-align: center;
  margin-top: 60px;
}

.auth-register-page .subTitle {
  font-size: 17px;
  text-align: center;
}

.auth-register-page .icon {
  display: flex;
  justify-content: center;
}

.auth-register-page .create-account {
  margin: 25px 15px;
}

.auth-register-page .form-field {
  width: 100%;
  margin-bottom: 40px;
}

.auth-register-page .form-field label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  margin-left: 15px;
}

.auth-register-page .create-account-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-register-page .account-separator {
  margin: 32px 0;
}
