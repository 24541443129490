.list-item-wrapper {
  position: relative;
  height: 150px;
  width: calc(50% - 16px);
  margin: 8px;
  overflow: hidden;
  border-radius: 12px;
}

.distanceCard {
  position: absolute;
  top: 10px;
  right: 10px;
  flex: 1;
  color: #c1a30b;
  font-weight: bold;
  padding: 2px 4px;
  border-radius: 8px;
  border: 1px solid #c1a30b;
  background-color: white;
  font-size: 13px;
}

.cardFooter {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  background-color: #00000080;
  padding: 2px 8px;
  width: 100%;
  border-radius: 0 0 12px 12px;
}

@media screen and (min-width: 768px) {
  .list-item-wrapper {
    width: calc(33.3% - 16px);
    height: 200px;
  }
  .cardFooter {
    gap: 20px;
    width: 100%;
  }
}

@media screen and (min-width: 820px) {
  .list-item-wrapper {
    width: calc(33.3% - 16px);
    height: 200px;
  }
  .cardFooter {
    gap: 20px;
    width: 100%;
  }
}

.list-item-wrapper .image {
  max-width: 100%;
  border-radius: 12px;
  min-height: 100%;
}

.list-item-wrapper .title {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 700;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-item-wrapper .title.hidden {
  color: transparent;
  text-shadow: 0 0 6px #fff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
}

.list-item-wrapper .description {
  overflow: hidden;
  white-space: nowrap;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: white;
  text-overflow: ellipsis;
}

.list-item-wrapper .cardFooter .logo {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.list-item-wrapper .cardFooterText {
  margin-left: 8px;
  overflow: hidden;
}
