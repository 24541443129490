.offer-page {
  color: #000000;
}

.offer-page .ion-spinner-wrapper {
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.offer-page .ion-spinner-wrapper ion-spinner {
  size: 200px;
}

.container-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 170px;
  position: relative;
}

.container-img svg {
  position: absolute;
  bottom: 0;
}

.container-information {
  padding: 16px;
}

.shop-informations {
  margin-top: 25px;
}

.shop-informations-category {
  color: black;
  font-size: 16px;
  text-transform: capitalize;
  margin-bottom: 0.5rem;
  width: 100%;
}

.txt-page-adress-info {
  font-size: 15px;
  color: #707783;
  margin-bottom: 1rem;
}

.text-page-description {
  margin-bottom: 1rem;
  color: #2f333a;
}

.txt-page-name-store {
  font-size: 28px;
  font-weight: bolder;
  margin-bottom: 0.5rem;
  width: 100%;
  color: #151515;
  text-transform: capitalize;
}

.txt-page-offre-bienvenue {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 0.8rem;
}

ion-card {
  background: linear-gradient(45deg, #292e2b 19.27%, #8a9388 100%);
  border-radius: 12px;
}

.card-title-store {
  font-weight: 600;
  font-size: 20px;
  background: linear-gradient(
    119.95deg,
    #c1a30b 0.92%,
    #ffeb64 59.82%,
    #edd74b 65.71%,
    #dac02f 73.56%,
    #ccb01b 82.4%,
    #c3a60f 90.25%,
    #c1a30b 99.09%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 100%;
}

.card-description-store {
  color: white;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.txt-page-location {
  font-weight: bolder;
  font-size: 20px;
  margin-bottom: 0.8rem;
}

.txt-page-location-map {
  width: 100%;
  height: 150px;
  border-radius: 10px;
}

.txt-page-addres {
  color: #000000;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.txt-page-adress-location {
  margin-bottom: 1rem;
}

.txt-page-horaires {
  font-weight: bolder;
  font-size: 20px;
  margin-bottom: 0.8rem;
}

.txt-page-contact {
  font-weight: bolder;
  font-size: 20px;
  margin-bottom: 0.8rem;
}

.contact-content {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.contact-content a {
  margin: 8px 0;
}

.back-button {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 10px;
}

.back-button svg {
  margin: 12px;
  color: #00aa77;
}

.card-unlimited-offer {
  display: flex;
}
.card-unlimited-offer .card-tooltip {
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgb(255, 255, 255);
}
.card-unlimited-offer .card-tooltip p {
  font-size: 12px;
}
.card-unlimited-offer-title {
  flex: 1;
}
