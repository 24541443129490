.content {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: white;
  color: black;
  border-radius: 10px;
  transition: 1s;
  box-shadow: 4px 24px 23px -4px rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.content img {
  object-fit: contain;
  height: 120px;
  width: 120px;
  padding: 4px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.content > div {
  margin-left: 8px;
}

.content p {
  margin: 8px 0;
}

.content h3 {
  font-size: 22px;
  margin-bottom: 5px;
}

.content h3.hidden {
  color: transparent;
  text-shadow: 0 0 9px #000;
}

.content .category {
  color: #888f9b;
  text-transform: uppercase;
  font-size: 12px;
}

.content .more-offers {
  font-size: 11px;
  font-weight: bold;
}
