.card-container {
  padding: 24px;
  background: linear-gradient(45deg, #292e2b 19.27%, #8a9388 100%);
  border-radius: 10px;
  box-shadow: 0px 4px 16px 0px #b3995899;
  position: relative;
}

.card-top-container {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.5rem;
}

.card-container .logo .text {
  font-family: 'Sans Encode Condensed';
  font-weight: 200;
  color: #ffffff;
  font-size: 22px;
}

.card-container .logo .text > span {
  font-family: 'Sans Encode Condensed';
  font-weight: 500;
}

.card-info-txt {
  font-weight: 600;
  font-size: 20px;
  background: -webkit-radial-gradient(rgb(199, 169, 59), rgb(248, 221, 100));
  background: linear-gradient(
    119.95deg,
    #c1a30b 0.92%,
    #ffeb64 59.82%,
    #edd74b 65.71%,
    #dac02f 73.56%,
    #ccb01b 82.4%,
    #c3a60f 90.25%,
    #c1a30b 99.09%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card-name {
  font-size: 20px;
  font-weight: 700;
  margin: 20px 0;
}

.container-info-card {
  display: flex;
  justify-content: space-between;
}

.info-row-txt-top {
  font-weight: 400;
  font-size: 13px;
  text-transform: uppercase;
}

.info-row-txt-down {
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 0;
}

.on-blur {
  filter: blur(4px);
}

.unlock-btn-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;
}

.unlock-btn-container > div {
  color: white;
  font-size: 11px;
  margin-top: 6px;
  color: #edd74b;
}

.unlock-btn-container button {
  background: linear-gradient(
    119.95deg,
    #c1a30b 0.92%,
    #ffeb64 59.82%,
    #edd74b 65.71%,
    #dac02f 73.56%,
    #ccb01b 82.4%,
    #c3a60f 90.25%,
    #c1a30b 99.09%
  );
  color: #000000;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 3;
}

.unlock-btn > span {
  font-size: 15px;
  font-weight: 600;
}
