.profile-page .profil-page-content {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.profile-page .card {
  margin: 16px 16px 20px;
}

.profile-page .logo-container {
  margin: auto 0 24px;
  padding: 0 16px;
}

.profile-page .member-from {
  margin: 0;
  font-size: 18px;
  color: #888f9b;
  text-align: center;
  margin-bottom: 0px;
}

.profile-page .member-date {
  color: #000000;
  font-weight: 600;
  font-size: 25px;
  text-align: center;
  margin-top: 0;
}

.profile-page .hello-name {
  color: #000000;
  font-weight: 600;
  font-size: 25px;
  text-align: center;
  margin-top: 0;
}

.profile-page .buy-now {
  margin: 0;
  font-size: 18px;
  color: #888f9b;
  text-align: center;
  margin-bottom: 24px;
}

.logout-btn-container {
  margin-top: 24px;
  padding: 0 16px;
}
