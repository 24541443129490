.auth-login-page .titre {
  font-size: 28px;
  text-align: center;
  margin-top: 60px;
  color: black;
}

.auth-login-page .icon {
  display: flex;
  justify-content: center;
}

.auth-login-page .create-account {
  margin: 25px 15px;
}

.auth-login-page .form-field {
  width: 100%;
  margin-bottom: 40px;
}

.auth-login-page .create-account-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.create-account-content-label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  background-color: white;
  margin-left: 15px;
  position: absolute;
  padding: 0 2px;
}

.auth-login-page .account-separator span {
  background-color: white;
}

.auth-login-page .create-account-forgot-pw {
  text-align: center;
  font-size: 13px;
  background-color: white;
  padding: 4px 0;
  margin: 20px 0;
}

.auth-login-page input {
  color: black;
}

.auth-login-page .account-separator {
  margin: 32px 0;
}
