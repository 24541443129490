.buy-page-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.presentation-card {
  background: linear-gradient(45deg, #292e2b 19.27%, #8a9388 100%);
  border-radius: 0px;
  margin: 0;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 50%;
}

.presentation-card .ion-card-title {
  color: white;
  font-weight: 600;
  font-size: 23px;
}

.ion-card-title .share-title {
  color: #c1a30b;
}

.presentation-card .ion-card-subtitle {
  color: white;
  line-height: 1.5;
  font-weight: 400;
  font-size: 16px;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  display: flex;
}

.presentation-card .ion-card-subtitle p {
  margin: 10px 0 0;
}

.presentation-card .card-content {
  color: white;
  font-size: 15px;
  align-self: center;
  padding-top: 16px;
}

.buy-page-content .buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 16px;
  flex: 1;
}

.buy-page-content .buy-button {
  color: white;
  --background: #c1a30b;
  align-self: center;
  --border-radius: 12px;
  width: 100%;
}

.buy-button:nth-child(3) {
  --background: #2d322f;
}

.buy-page .have-one-button {
  --background: #c1a30b;
}

.buy-page .logo-container {
  margin: auto 0 16px;
}
