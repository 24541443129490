.timeTable {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
}

.dayName {
  color: #707783;
  font-size: 17px;
  font-weight: 400;
}

.time {
  color: #000000;
  margin-left: auto;
}
