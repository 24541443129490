ion-chip {
  background: #ffffff;
  color: #c1a30b;
  border: 1px solid #c1a30b;
}

ion-chip.selected {
  background: #c1a30b;
  color: #ffffff;
}
